<template>
  <ul class="list-none">
    <folder
      v-for="(nodeData, nIndex) in directoryTreeData"
      :key="nodeData.id || 'node' + nIndex"
      v-bind="{nodeData, selectedNode, nameProperty, subTreeProperty, selectedNodeClass, ...folderProps}"
      @nodeSelected="emitNodeSelected($event)"
    >
      <template
        v-for="(_, name) in slots"
        #[name]
        :key="'base-folder-slot' + name"
      >
        <slot :name="name" />
      </template>
    </folder>
  </ul>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'BaseDirectoryTree',

    components: {
        folder: defineAsyncComponent(() => import('./Folder.vue'))
    },

    props: {
        directoryTreeData: {
            type: Array,
            required: true,
            description: 'Array for directory tree with children'
        },

        folderProps: {
            type: Object,
            default: () => {},
            description: 'Folder component props object(check folder.vue for supported props)'
        },

        selectedNode: {
            type: Object,
            default: () => {},
            description: 'data of selected node in directory tree'
        },

        nameProperty: {
            type: String,
            default: 'name',
            description: 'Property to be used for name of folder(default is name)'
        },

        subTreeProperty: {
            type: String,
            default: 'children',
            description: 'Property to be used for sub-tree(default is children)'
        },

        selectedNodeClass: {
            type: String,
            default: '',
            description: 'applies specified classes on selected node'
        }
    },

    emits: [
        'nodeSelected'
    ],

    setup (props, { slots, emit }) {
        const emitNodeSelected = (e) => {
            emit('nodeSelected', e);
        };
        return {
            slots,
            emit,
            emitNodeSelected
        };
    }
};
</script>
